@import '../../../variables.scss';

.bymaTextField {
  //PABLO TODO esto trae problemas cuando el input esta en la ultima fila
  //deberiam manejarse los margenes de otra forma
  margin-bottom: 16px;

  .fieldLabel {
    color: white;
    margin-bottom: 8px;
    //margin-bottom: 0.5%;

    font-weight: $font_weight_bold;
    font-size: 16px;
    line-height: 24px;
    font-family: var(--font-family-regular);
    //En la version default esta alineado a izq totalmente
    //padding-left: 0.5em;
  }

  .inputInnerAddon input[type='password'] {
    -webkit-text-security: square;
    width: 100%;
  }

  .inputFormField {
    color: white;
    outline: none;
    border: none;
  }

  .inputFormField:read-only {
    pointer-events: none;
  }

  //.inputInnerAddon:read-only,
  .inputInnerAddonReadOnly {
    border: 1px solid var(--gray-color-dark) !important;
  }

  .inputInnerAddonLarge {
    padding: 12px 12px 12px 13px;
  }

  .inputInnerAddon:focus-within {
    border: 1px solid var(--byma-primary-2);
  }

  .inputInnerAddon {
    position: relative;

    width: 100%;
    border: 1px solid var(--byma-gray-2);
    border-radius: 20px;
    display: flex;
    align-items: center;

    &.inputFormFieldError {
      border: 1px solid var(--badge-text-red);
    }

    input {
      width: 100%;
      padding: 0;
      line-height: 100%;
    }

    //Hacky solution for the autofill styling
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000000s ease-in-out 0s;
      -webkit-text-fill-color: white;
    }

    .inputLeftAddon {
      pointer-events: none;
    }

    .inputRightAddon {
      width: 20.9px;
    }

    .leftIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    .rightIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      margin-right: 8px;
      cursor: pointer;

      img {
        max-width: 20px;
      }
    }

    .errorText {
      margin-top: 4px;
    }
  }
  .inputInnerAddonSmall {
    padding: 6px 12px 6px 6px;
    font-size: $font_small;
    .leftIcon,
    .rightIcon {
      width: 16px;
      height: 16px;
      img {
        max-width: 14px;
      }
    }

    input {
      line-height: 14px;
      padding: 1px 6px 1px 10px;
    }
  }

  .inputInnerAddonDefault {
    padding: 6px 12px;

    input {
      font-family: var(--font-family-regular);
      font-size: $font_large;
      font-weight: $font_weight_light;
      line-height: 24px;
      letter-spacing: 0.07px;
    }
  }
}
.bymaTextFieldSmall {
  .fieldLabel {
    font-size: $font_large;
    line-height: 16px;
    letter-spacing: 0.07px;
    padding-left: 0.5em;
  }
}
