.roles-edit-container {
  .first-row {
    margin-top: 33px;
  }

  .roles-actions {
    padding: 0;
    margin: 0 33px;
    width: 102px;
  }

  .roles-edit-form {
    .export-buttom-col {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }

    .byma-dual-list-container {
      min-height: 500px;
      padding: 0 !important;
      display: block !important;
    }
    .byma-dual-list-box {
      margin-top: 36px;
      margin-bottom: 26px;
      .rdl-controls {
        align-items: center;
      }

      .rdl-controls .rdl-list-box .rdl-filter-container {
        padding: 20px 43px 10px 20px;

        label {
          font-family: var(--font-family-regular);
          font-size: 14px;
        }

        input {
          border-color: inherit;
        }
      }

      .rdl-controls .rdl-list-box .rdl-control-container .rdl-control {
        padding: 0;
        option {
          padding: 11px 24px;
          letter-spacing: 0.005em;
          font-family: var(--font-family-regular);
          white-space: normal;
        }
      }

      .rdl-actions-right {
        margin-bottom: 20px;
      }

      .rdl-control-container,
      .rdl-no-options {
        height: 370px;
        background: var(--secondary-gray-light);
        padding-bottom: 20px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }

      .rdl-no-options {
        padding-left: 24px;
        padding-right: 24px;
        font-size: 14px;
        line-height: 15px;
        font-family: var(--font-family-regular);
        letter-spacing: 0.005em;
        padding-top: 4px;
      }
    }

    .byma-dual-list-container {
      .rdl-list-box > .rdl-control-label {
        letter-spacing: 0.05em;
        font-family: var(--font-montserrat-light);
        padding-bottom: 16px;
      }
    }

    /*.form-action-buttons {
      float: right;
      display: flex;
      column-gap: 25px;

      button {
        height: 33px;
        border-radius: 50px;
      }
    }*/
  }
}
