.bymaTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 0.25px solid #ced4da;
  margin: 0;
  padding-bottom: 8px;
  padding-left: 14px;
  padding-right: 0px !important;

  .leftContainer {
    display: flex;
  }

  .titleIconContainer {
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .titleIcon {
    height: 22px;
  }

  .titleText {
    margin-left: 10px;
    font: var(--font-montserrat-light);
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 2.5px;
    text-transform: uppercase;
  }
}
