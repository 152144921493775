.byma-badge {
  padding: 3px 10px 4px 10px !important;
  border-radius: 10px !important;
  font-size: 12px !important;
  font-family: var(--font-family-regular) !important;
  font-weight: 400 !important;
  line-height: 12px !important;
  text-align: center !important;
  letter-spacing: 0.06px !important;
  // Wrap text
  display: math !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.byma-badgeBig {
  line-height: 24px !important;
  font-size: 16px !important;
}

.byma-badgePorcentajeOfertado {
  padding: 3px 45px 4px 45px !important;
}
.byma-badge.bg-success {
  background: rgba(79, 174, 34, 0.2) !important;
  color: var(--byma-success);
}

.byma-badge.bg-pending {
  background: var(--badge-background-yellow) !important;
  color: var(--badge-text-yellow) !important;
}

.byma-badge.bg-active {
  background: var(--badge-background-green) !important;
  color: var(--badge-text-green) !important;
}

.byma-badge.bg-error {
  background: var(--badge-background-red) !important;
  color: var(--badge-text-red) !important;
}

.byma-badge.bg-data {
  background: var(--gray-color-dark2) !important;
  color: white !important;
}

.byma-badge.bg-grey {
  background: var(--gray-color-dark) !important;
  color: white !important;
}

.byma-badge.bg-info {
  background: var(--badge-background-blue) !important;
  color: var(--badge-text-blue) !important;
}

.byma-badge.bg-warning {
  background: #ffc10733 !important;
  color: #ffc107 !important;
}

.byma-badge.bg-unknown {
  background: transparent !important;
  color: white !important;
}


.byma-badge.bg-data-cell {
  font-family: var(--font-family-regular) !important;
  background: #ffffff0d !important;
  color: white !important;
  display: block !important;
  margin-top: 1em;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
