.react-datepicker {
  font-family: var(--font-family-regular);
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.react-datepicker__input-container {
  //border-radius: 10px !important;
  //background-color: var(--byma-button-gray) !important;
  border: none !important;

  //PABLO: elimino este max-width para que el input cubra todo el container
  //esto muevo el boton de clear al extremo derecho pero fucionalmente me parece mejor
  //el usuario tiene mas area para clickear y desplegar el calendario y no se puede clickear el boton "oculto"
  //
  //max-width: 80%;
}

.react-datepicker__day--selected {
  background-color: var(--byma-primary-2) !important;
}

.react-datepicker__time {
  background-color: var(--secondary-gray-light) !important;
}

.react-datepicker__time-list-item--selected {
  border-radius: 0.3rem;
  background-color: var(--byma-primary-2) !important;
}

.react-datepicker__time-list-item--selected:hover {
  border-radius: 0.3rem;
  background-color: var(--byma-primary-2) !important;
}

.react-datepicker__header {
  background-color: transparent;
  border: none !important;
}

.react-datepicker__triangle::after,
.react-datepicker__triangle::before {
  border-top-color: var(--secondary-gray-light) !important;
  border-bottom-color: var(--secondary-gray-light) !important;
}

.react-datepicker__current-month {
  color: white;
}

.react-datepicker__navigation-icon {
  margin-top: 40%;
}

.react-datepicker__navigation-icon::before {
  border-width: 1.5px 1.5px 0 0;
}

.react-datepicker-time__header {
  color: white;
  font-weight: 400;
}

.react-datepicker__time-container {
  margin-top: 15px;
  margin-bottom: 15px;
}

.react-datepicker__time-list-item:hover {
  background: var(--byma-button-gray) !important;
}

// Scrollbar
.react-datepicker__time-list {
  scrollbar-color: var(--primary-gray-dark) transparent;
  scrollbar-width: thin;
}
.react-datepicker__time-list::-webkit-scrollbar,
select::-webkit-scrollbar {
  width: 7px;
  height: 0px;
}
.react-datepicker__time-list::-webkit-scrollbar-track,
select::-webkit-scrollbar-track {
  background: var(--secondary-gray-light);
  //border-radius: 50px;
}
.react-datepicker__time-list::-webkit-scrollbar-thumb,
select::-webkit-scrollbar-thumb {
  background: var(--primary-gray-dark);
  border-radius: 50px;
}
.react-datepicker__time-list::-webkit-scrollbar-thumb:hover,
select::-webkit-scrollbar-thumb:hover {
  background: var(--primary-gray-dark);
}

.react-datepicker__day--outside-month {
  opacity: 0.4;
  pointer-events: none;
}

.react-datepicker__day {
  font-weight: 300 !important;
}

.react-datepicker__day--today {
  border: 1px solid #0183d9 !important;
}
