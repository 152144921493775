@import '../../../variables.scss';

.selectFieldInputSingleValue {
  color: white !important;
}

.selectFieldInputDefault {
  color: white !important;
  /* TODO chequear libreria: definir variable*/
  font-weight: 300;
  font-size: $font_large;
}

.selectFieldInputSmall {
  font-size: $font_large;
  color: white !important;
  /* TODO chequear libreria: definir variable*/
  font-weight: 300;
}

.selectFieldInputHidden {
  //En caso de que se no se permita editar descomentar
  //display: none !important;

  width: 1px !important;
  padding: 4px 0px 4px 0px !important;
  margin: 0px 0px 0px 0px !important;
}

.selectFieldInputMultiValue {
  border-radius: 10px !important;
  background-color: var(--byma-button-gray) !important;
  border: none !important;

  padding: 2px 6px 2px 6px;
  //PABLO Se reduce padding left para hacer mas lugar
  //padding: 2px 6px 2px 10px;

  //El porcentaje es para dejar lugar para el input
  max-width: 91%;
  //max-width: 80%;
}

.selectFielMultiValueGeneric {
  color: var(--selected-color) !important;
  padding: 0 !important;
  font-size: 12px !important;
}

.selectFielMultiValueRemove {
  background: var(--byma-button-gray) !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border: none !important;
  padding-right: 0 !important;
  color: var(--selected-color) !important;
}

.selectFielMultiValueRemove:hover {
  background: var(--byma-button-gray);
  color: var(--selected-color) !important;
  opacity: 0.8;
}

.indicatorSeparator {
  display: none;
}

.selectFieldControlReadOnly {
  color: var(--gray-color-dark) !important;
  border: 1px solid var(--gray-color-dark) !important;
  border-radius: 20px;
  --bs-bg-opacity: 1;
  border-radius: 20px !important;
  background-color: transparent !important;
}

.selectFieldControlDefault {
  color: white;
  border: 1px solid #ced4da;
  border-radius: 20px;
  --bs-bg-opacity: 1;
  border-radius: 20px !important;
  background-color: transparent !important;
}

.selectFieldControlError {
  color: white;
  border: 1px solid var(--badge-text-red) !important;
  --bs-bg-opacity: 1;
  border-radius: 20px !important;
  background-color: transparent !important;
}

.selectFieldControlFocusDefault {
  outline: none !important;
  border: 1px solid #1a73e8 !important;
  border-radius: 20px !important;
  background-color: transparent !important;
}

.selectValueContainerSmall {
  // se reduce padding right para hacer mas espaxio
  padding: 2px 2px 2px 8px !important;
}

.selectValueContainerReadOnly {
  padding: 2px 8px;
  color: var(--gray-color-dark) !important;
}

.selectValueContainerDefault {
  padding: 2px 8px;
}

.selectFieldMenu {
  outline: none !important;
  background-color: var(--byma-button-gray) !important;
  border: none !important;
  border-radius: 18px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  --bs-bg-opacity: 1;
  z-index: 3000 !important;
}

.selectFieldMenuList {
  --bs-bg-opacity: 1;
  margin-bottom: 6px !important;
  margin-top: 6px !important;

  z-index: 5;
  //background-color: var(--byma-button-gray) !important;
  scrollbar-color: var(--primary-gray-dark) transparent;
  scrollbar-width: thin;
}

.selectFieldMenuList::-webkit-scrollbar {
  width: 7px !important;
  height: 0px !important;
}
.selectFieldMenuList::-webkit-scrollbar-track {
  background: var(--secondary-gray-normal) !important;
  border-radius: 50px !important;
  //margin-top: 10px !important;
  //margin-bottom: 10px !important;
}
.selectFieldMenuList::-webkit-scrollbar-thumb {
  background: var(--primary-gray-dark) !important;
  border-radius: 50px !important;
}
.selectFieldMenuList::-webkit-scrollbar-thumb:hover {
  background: var(--primary-gray-dark) !important;
}

.selectFieldMenuOptionSmall {
  --bs-bg-opacity: 1;
  display: 'flex';
  align-items: center;
  background-color: var(--byma-button-gray) !important;
  label {
    margin: 0 !important;
    font-size: $font_small !important;
  }
}

.selectFieldMenuOptionDefault {
  --bs-bg-opacity: 1;
  background-color: var(--byma-button-gray) !important;
}

/*.selectFieldMenuOption:first-child {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}

.selectFieldMenuOption:last-child {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}*/

.selectFieldMenuOptionDefault:hover {
  --bs-bg-opacity: 1;
  background-color: var(--secondary-gray-light) !important;
}

.selectFieldMenuOptionSmall:hover {
  --bs-bg-opacity: 1;
  background-color: var(--secondary-gray-light) !important;
}

//TODO esta seria para la variante Default
.selectFormGroup {
  width: 100%;
  height: inherit;

  .bymaSelect {
    label {
      //TODO revisar este margin-bottom deberia ser una variable
      //ya que todos los widgets con label debenestar alineados horizontalmente
      margin-bottom: 0.5em;
      font-size: $font_xlarge;
      font-weight: $font_weight_bold;
      font-family: var(--font-family-regular);
    }
  }
  .bymaSelectSmall label {
    padding-left: 0.5em;
    font-size: $font_large;
    line-height: 15.4px;
    letter-spacing: 0.07px;
    font-family: var(--font-family-regular);
  }
}

.bymaSelect {
  .bymaSelectLabel {
    //En la version default esta alineado a izq totalmente
    //padding-left: 0.5em;
    margin-bottom: 0.5em;
    font-weight: $font_weight_bold;
    font-family: var(--font-family-regular);
  }
}
.bymaSelectSmall {
  .bymaSelectLabel {
    padding-left: 0.5em;
    font-size: $font_large;
    text-align: center;
    line-height: 15.4px;
    letter-spacing: 0.07px;
    font-family: var(--font-family-regular);
  }
}

.form-field-error {
  border: 1px solid red !important;
}

.selectFieldControlSmall {
  font-size: $font_small;
  line-height: 12px;
  letter-spacing: 0.06px;
  color: white;
  border: 1px solid #ced4da;
  border-radius: 20px;
  --bs-bg-opacity: 1;
  border-radius: 20px !important;
  background-color: transparent !important;
}

.selectFieldControlFocusSmall {
  font-size: $font_small;
  line-height: 12px;
  letter-spacing: 0.06px;
  outline: none !important;
  border: 1px solid #1a73e8 !important;
  border-radius: 20px !important;
  background-color: transparent !important;
}

.indicatorsContainerSmall > div {
  padding: 4px 2px 4px 0px;
  //PABLO para hacer mas lugar para el valor se reduce padding left yun poco el right
  //padding: 4px 8px;
}

.bymaTextFilter {
  .textInputFilter {
    text-align: center;
  }

  .inputLabel {
    margin-bottom: 0.5em;
    //padding-left: 3%;
    //En la version default esta alineado a izq totalmente
    //padding-left: 0.5em;

    font-size: $font_large;
    font-weight: $font_weight_bold;
    font-family: var(--font-family-regular);
  }
}

.bymaTextFilterSmall,
.bymaTextFilterDefault {
  font-size: $font_large;

  .inputLabel {
    text-align: center;
    padding-left: 0.5em;
    line-height: 15.4px;
    letter-spacing: 0.07px;
    font-family: var(--font-family-regular);
  }
}
