@import url('https://unpkg.com/bootstrap-darkmode@5.0.1/css/darktheme.css');
@import url('https://coliff.github.io/bootstrap-show-password-toggle/css/show-password-toggle.css');
@import url('https://raw.githubusercontent.com/davidstutz/bootstrap-multiselect/master/dist/css/bootstrap-multiselect.css');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css');

@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import './variables.scss';
:root {
  --font-family-regular: 'Inter';
  --font-roboto-regular: 'Roboto';
  --font-family-bold: 'Inter-bold';
  --font-family-light: 'Inter';
  --font-family-extra-light: 'Extra-light';
  --font-montserrat-light: 'Montserrat';
  --primary-color-white: #ffffff;
  --primary-color-light: #00acc8;
  --primary-color-normal: #0071ce;
  --byma-primary-2: #0183d9;
  --gray-color-dark: #6c757d;
  --gray-color-dark2: #575b69;
  --byma-gray-2: #ced4da;
  --error-color: #d00909;
  --border-color: #d9d9d9;
  --selected-color: #d9d9d9;
  --byma-blanco: rgba(255, 255, 255, 1);
  --transparent-black-2: rgba(0, 0, 0, 0);
  --transparent-black-3: rgba(28, 27, 31, 0);
  --transparent-black-4: rgba(28, 27, 31, 0.2);
  --byma-button-gray: #626573;
  --byma-button-blue: #0183d9;
  --byma-success: #4fae22;
  --primary-gray-dark: #262a3d;
  --secondary-gray-normal: #3b3f50;
  --secondary-gray-light: #4e5261;
  --badge-text-blue: #1497ee;
  --badge-background-blue: rgba(20, 151, 238, 0.2);
  --badge-text-yellow: #ffc107;
  --badge-background-yellow: rgba(255, 193, 7, 0.2);
  --badge-text-green: #4fae22;
  --badge-background-green: rgba(79, 174, 34, 0.2);
  --badge-text-red: #f75555;
  --badge-background-red: rgba(247, 85, 85, 0.2);
  scrollbar-color: var(--primary-gray-dark) transparent;
  scrollbar-width: thin;
}

[data-theme='dark'] {
  color: #f8f9fa;
  background-color: var(--primary-gray-dark);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.container-fluid {
  padding-left: 0% !important;
  padding-right: 0% !important;
}

.App-header {
  background-color: var(--primary-gray-dark);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-container {
  .container {
    max-width: 100% !important;
    width: 100% !important;
    height: 100%;
  }
  width: calc(100% - 40px);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// Scrollbar
.select-field {
  scrollbar-color: var(--primary-gray-dark) transparent;
  scrollbar-width: thin;
}
.select-field::-webkit-scrollbar,
select::-webkit-scrollbar {
  width: 7px;
  height: 0px;
}
.select-field::-webkit-scrollbar-track,
select::-webkit-scrollbar-track {
  background: var(--secondary-gray-light);
  border-radius: 50px;
}
.select-field::-webkit-scrollbar-thumb,
select::-webkit-scrollbar-thumb {
  background: var(--primary-gray-dark);
  border-radius: 50px;
}
.select-field::-webkit-scrollbar-thumb:hover,
select::-webkit-scrollbar-thumb:hover {
  background: var(--primary-gray-dark);
}

/* BYMA: Number input without arrows*/
/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

#button-tooltip {
  position: fixed;
}

.MuiPaper-root {
  background-color: transparent !important;
}

.MuiInputBase-root,
.MuiInputBase-input {
  &:focus-visible {
    outline: 0 !important;
  }
}

.MuiClockNumber-root {
  color: #ffffff !important;
}

.MuiClock-clock {
  background-color: #494550 !important;
}

.MuiTimePickerToolbar-hourMinuteLabel {
  padding: 10 !important;
}

.MuiPickersArrowSwitcher-root {
  button {
    color: #ffffff !important;
  }
}
.MuiClock-pin {
  background-color: #cebafd !important;
}

.MuiClockPointer-root {
  background-color: #cebafd !important;
}

.MuiClockPointer-thumb {
  background-color: #302d38 !important;
  border-color: #cebafd !important;
}

.MuiPickersToolbar-root {
  .Mui-selected {
    background-color: #4f378b !important;
  }
}

.MuiClock-amButton,
.MuiClock-pmButton {
  background-color: transparent !important;
  span {
    color: #ffffff !important;
  }
}

.admin-scrolling-dropdown-titulo {
  .dropdown-menu {
    background-color: var(--byma-button-gray) !important;
    margin-top: 10px;
    border-radius: 18px;
    padding: 12px 16px;
    gap: 8px;
    position: absolute;
    inset: 0px 0px auto auto;
    transform: translate(0px, 33px) !important;
  }
}
