.byma-breadcrumb {
  height: 100%;

  ol {
    margin-bottom: 0;
  }

  .breadcrumb-item {
    font: var(--font-family-light);
    font-weight: 200;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.5%;
    align-items: Center;
    color: var(--byma-blanco);
  }

  .breadcrumb-item a {
    color: var(--byma-blanco);
  }
}
