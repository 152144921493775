.byma-modal-container {
  //top: 30% !important;

  .modal-content {
    background: var(--secondary-gray-normal) !important;
    //se comnenta el borde para respectar el disenio de los formularios modales
    //si hay variantes, indecarlo con un parametro
    //border: 1px solid #0183d9;
    //border: 1px solid;
    border-radius: 20px;
    border: none;

    .modal-header {
      border-bottom: none !important;
    }

    .modal-footer {
      border-top: none !important;
    }

    // Scrollbar
    .modal-body {
      scrollbar-color: var(--primary-gray-dark) transparent;
      scrollbar-width: thin;
    }
    .modal-body::-webkit-scrollbar,
    select::-webkit-scrollbar {
      width: 7px;
      height: 0px;
    }
    .modal-body::-webkit-scrollbar-track,
    select::-webkit-scrollbar-track {
      background: var(--secondary-gray-light);
      border-radius: 50px;
    }
    .modal-body::-webkit-scrollbar-thumb,
    select::-webkit-scrollbar-thumb {
      background: var(--primary-gray-dark);
      border-radius: 50px;
    }
    .modal-body::-webkit-scrollbar-thumb:hover,
    select::-webkit-scrollbar-thumb:hover {
      background: var(--primary-gray-dark);
    }
  }
}
