.navDropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    //padding: 4px 8px;
    font-size: 16px;
    border: 1px solid rgba(206, 212, 218, 1);
    width: 185px;
    .navDropdownToggle {
      background-color: transparent !important;
      border: none;
      width: 100%;
      &:hover {
        background-color: transparent !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
      }
      &:active {
        background-color: transparent !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
      }
      &:focus {
        background-color: transparent !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
      }
      &:focus-visible {
        background-color: transparent !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
      }
    }
  
    .dropdownMenu {
      background-color: var(--byma-button-gray) !important;
      margin-top: 10px;
      border-radius: 18px;
      padding: 12px 16px;
      gap: 8px;
      inset: 0px 0px auto auto;
      transform: translate(0px, 40px) !important;
      min-width: 185px;
    }
  }
  
  
.linkContainer {
    color: #ffffff;
    padding: 6px 8px;
    &:hover {
      background-color: #4e5261;
      border-radius: 18px;
    }
  }


//TODO: en vez de copiar estas definiciones desde BymaBadge.scss reutilizarlas
//Podemos usar una funcion comun useBadgeColorsClassname

.successColors {
    background: rgba(79, 174, 34, 0.2) !important;
    color: var(--byma-success);
}
  
.pendingColors {
    background: var(--badge-background-yellow) !important;
    color: var(--badge-text-yellow) !important;
}
  
.activeColors {
    background: var(--badge-background-green) !important;
    color: var(--badge-text-green) !important;
}
  
.errorColors {
    background: var(--badge-background-red) !important;
    color: var(--badge-text-red) !important;
}
  
.dataColors {
    background: var(--gray-color-dark2) !important;
    color: white !important;
}
  
.greyColors {
    background: var(--gray-color-dark) !important;
    color: white !important;
}
  
.infoColors {
    background: var(--badge-background-blue) !important;
    color: var(--badge-text-blue) !important;
}
  
.warningColors {
    background: #ffc10733 !important;
    color: #ffc107 !important;
}

.unknownColor {
  background: transparent !important;
  color: white !important;
}
  