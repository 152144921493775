.byma-alert {
  //font-family: var(--font-family-regular) !important;

  .btn-close {
    filter: inherit !important;
    padding: 15px 20px !important;
  }
}

.alert-dismissible {
  display: flex;
  padding: 11px 56px 11px 16px !important;
}

.alert-primary {
  background: rgba(1, 131, 217, 0.1) !important;
  border-color: var(--byma-primary-2) !important;
  color: var(--byma-primary-2) !important;
}

.alert-danger {
  background: rgba(247, 85, 85, 0.2) !important;
  border-color: var(--badge-text-red) !important;
  color: var(--badge-text-red) !important;
}

.alert-info {
  background: rgba(255, 193, 7, 0.2) !important;
  border-color: var(--badge-text-yellow) !important;
  color: var(--badge-text-yellow) !important;
  p {
    color: var(--badge-text-yellow) !important;
  }
}
