[data-theme='dark'] .bymaHelpBox {
  background-color: transparent !important;
  border: none;

  .cardHeader {
    background-color: transparent !important;
    border: none;
    font-family: var(--font-family-light);
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    padding-left: 0px;
    padding: 0;
    margin-bottom: 19px;
  }

  .cardBody {
    background-color: transparent !important;
    border: none;
    font-family: var(--font-family-light);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 0px;
  }
}
