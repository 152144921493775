.exportGroupButton {
  //position: absolute;

  img {
    left: 37% !important;
  }

  button {
    //background-color: #626573 !important;
    //border: 1px solid #626573 !important;
    width: 48px;
    height: 33px;
  }

  //.byma-button:hover {
  //background-color: #626573 !important;
  //border: 1px solid #626573 !important;
  //}
}
