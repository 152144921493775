.accordion {
  .accordion-item {
    .activeItem {
      background-color: #555967 !important;
      border-radius: 20px;
    }
    .accordion-header {
      min-height: 56px;
      border-radius: 20px;
      .accordion-button {
        padding: 12px 20px;
        pointer-events: none;
        border-radius: 20px !important;
      }

      .accordion-button::after {
        cursor: pointer !important;
        pointer-events: all;
      }
    }
  }

  .accordion-item .accordion-button.collapsed {
    border-radius: 20px !important;
  }
}
.accordion-collapse .accordion-body {
  padding: 0;
}
