.bymaPairText {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5%;

  .bymaPairValue {
    font-weight: 300;
  }
}
