.byma-dual-list-container {
  min-height: 500px;
  padding-right: 84px;
  padding-left: 84px;
  display: flex;
  flex-direction: column;
  .byma-dual-list-box {
    display: flex;
    flex: 1;
  }

  .error-text {
    color: red;
    padding-left: 3%;
    height: 24px;
  }
}
