.copyTextButton_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.copyTextButton_text {
  &:hover {
    text-decoration: underline;
  }
}
.copyTextButton {
  background-color: transparent !important;
  border: none;
}
