.bymaClock {
  span {
    font-family: var(--font-family-regular);
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.05em;
    color: var(--byma-primary-2);
    border-radius: 20px;
    padding: 3px 5px 3px 5px;
    background-color: #0183D926;
  }
}
