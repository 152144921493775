.bymaSidebar {
  display: flex;
  flex-direction: row;
}

.bymaSidebarDrawer {
  background-color: var(--secondary-gray-normal);
  border-radius: 20px;
  min-width: 50px;
  min-height: 300px;
  max-height: fit-content;
}

.bymaTreeItem {
  padding: 6px 8px;
}

.newSidebar {
  font-family: var(--font-montserrat-light);
}
