.itemContent {
  border-radius: 20px;
  background: #3b3f50;
}

.handlerContent {
  padding-top: 1%;
  border: none;
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 20px;
  justify-content: space-between;
}

.titleSpan {
  left: 24px;
  font-family: var(--font-family-regular);
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}
