@import '../../../variables.scss';

.bymaDropdownButton {
  .dropdownToggle::after {
    display: none;
  }

  .dropdownToggle {
    background-color: transparent !important;
    border: 1px solid var(--border-color) !important;
    border-radius: 18px;
    height: 33px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5%; 

    font-family: var(--font-family-regular);
    font-weight: $font_weight_bold;
    font-size: 14px;
    line-height: 15.4px;

    padding-right: 20px;
    padding-left: 20px;
    

    .iconLeftAddon,
    .iconRightAddon {
      cursor: pointer !important;
      //width: 20px;
    }
  }

  .bymaDropdownMenu {
    background-color: var(--byma-button-gray) !important;
    border: 1px solid var(--byma-button-gray) !important;
    color: white !important;
    padding-left: 10px !important;
    margin-top: 2%;

    font-family: var(--font-family-regular);
    font-weight: $font_weight_bold;
    font-size: 14px;

    //esto hace qque se alinea a la izquierda (para el boton de editar widgets)
    //si se usa en otro contexto verificar como queda y en todo caso usar estilos parametrizables
    inset: 0px 0px auto auto !important;

    input[type='checkbox'] {
      background-color: var(--byma-button-gray) !important;
      border-color: var(--byma-gray-2) !important;
      border: 1px solid var(--byma-gray-2);
   
    }
  }
}
