.importOfertasContent {
    display: flex;
    flex-direction: column;
    gap: 2%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 20px;

    .stepsButtons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        padding-top: 3%;

        .stepsRightButtons,
        .stepsLeftButtons {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
        }
    }

    .importOfertasSteps {
        width: 85% !important;
        margin-left: 20%;

        .importOfertasStep {
            display: flex;
            flex-direction: row;
            gap: 10px;
            padding-bottom: 2%;
            align-items: center;

            .stepNumber {
                width: 10%;
                align-self: center;
                justify-content: left;
                display: flex;

                span {
                    background-color: #0183D9;
                    border-radius: 50%;
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    font-size: 16px;
                    font-weight: 600;
                }
            }

            .stepDescription {
                width: 65%;
                justify-content: left;
                font-family: var(--font-family-regular);
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
            }
        }
    }

    .importOfertasPreview {

        .previewHeader {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .tableTitle {
                display: flex;
                flex-direction: row;
                gap: 5px;
            }

            .tableSubs {
                display: flex;
                flex-direction: row;
                gap: 10px;

                span {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 15.4px;
                    letter-spacing: 0.5%;
                }

                .fileName {
                    background-color: var(--byma-button-gray);
                    border-radius: 20px;
                    padding: 2px 10px 2px 10px;
                    color: #d2d4da;
                    font-size: 12px;
                    font-weight: 400;
                    font-family: var(--font-family-regular);
                }
            }
        }

        .previewImportButtons {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;

            .previewLeftButtons {
                display: flex;
                flex-direction: row;
            }

            .previewRightButtons {
                display: flex;
                flex-direction: row;
                gap: 10px;
            }
        }
    }
}