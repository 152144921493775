.table {
  border-collapse: collapse !important; /*Para eliminar los bordes de la tabla*/
  width: 100%; /*Ancho de la tabla al 100%*/
}

.tableScrollPane {
  height: 350px;
  overflow-x: hidden;
  overflow-y: scroll;
}

/* 
border-radius para agrupar datos de una colocacio

first value applies to top-left corner, 
second value applies to top-right corner, 
third value applies to bottom-right corner, 
fourth value applies to bottom-left corner 

TODO: usar sacss y variables para definir el border radius una sola vez
*/
.firstTdSingleRow {
  border-radius: 20px 0 0 20px !important;
}

.lastTdSingleRow {
  border-radius: 0 20px 20px 0 !important;
}

.firstTdFirstRow {
  border-radius: 20px 0 0 0px !important;
}

.lastTdFirstRow {
  border-radius: 0 20px 0 0 !important;
}

.firstTdLastRow {
  border-radius: 0 0 0 20px !important;
}

.lastTdLastRow {
  border-radius: 0 0 20px 0 !important;
}

.singleTdLastRow {
  border-radius: 0 0 20px 20px !important;
}

.trSpacer {
  height: 10px;
}

.tdCommon {
  border: none;
}

.buttonColocacion {
  background-color: var(--byma-button-gray) !important;
  border: 1px solid var(--byma-button-gray) !important;
  cursor: pointer !important;
  pointer-events: all;
}

.buttonOferta {
  cursor: pointer !important;
  pointer-events: all;
}

.ofertaRapidaCard {
  background-color: var(--secondary-gray-normal) !important;
}

.ecoIcon_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  height: 24px;
  width: 24px;
}

.denominationContainer {
  font-size: 16px;
  display: flex;
  align-items: center;
  overflow: hidden;

  p {
    font-weight: 300;
  }
}

.rightColBtnContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
