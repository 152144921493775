@import '../../../variables.scss';

.datePickerFormGroup {
  .wrapperDatePicker,
  .wrapperDatePickerDefault {
    color: white;
    width: 100%;
    background-color: transparent;
    border: 1px solid var(--byma-gray-2);
    border-radius: 20px;
    line-height: 12px;
    padding: 3px 2px 3px 8px !important;
  }

  .wrapperDatePicker {
    padding: 4px 2px 4px 8px !important;
  }

  .calendarIconContainer {
    position: absolute;
    right: 10px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .bymaDatePickerSmall,
  .bymaDatePickerSmall:focus {
    background-color: transparent;
    border: none !important;
    color: white;
    outline: none;
    font-size: $font_large !important;
  }

  .bymaDatePickerDefault,
  .bymaDatePickerDefault:focus {
    background-color: transparent;
    border: none !important;
    color: white;
    outline: none;
    font-size: $font_large !important;
  }

  .fieldLabel {
    margin-bottom: 0.5em;
    font-weight: 400;
  }

  .bymaDatePickerDefault {
    .fieldLabel {
      padding-left: 0.5em;
      font-size: $font_xlarge;
      text-align: center;
      line-height: 24px;
      letter-spacing: 0.07px;
      font-family: var(--font-family-regular);
    }
    .wrapperDatePickerDefault input {
      font-family: var(--bs-body-font-family);
      font-size: $font_large;
      line-height: 24px;
      letter-spacing: 0.07px;
      font-weight: 400;
      border-radius: 20px;
      max-width: 97%;
      margin: 2px;
      width: 80%;
    }

    .wrapperDatePickerDefault:focus-within {
      outline: none !important;
      border: 1px solid #1a73e8 !important;
      background-color: transparent !important;
    }

    .wrapperDatePicker input {
      font-family: var(--bs-body-font-family);
      font-size: $font_large;
      line-height: 24px;
      letter-spacing: 0.07px;
      padding-left: 0.5em;
      padding-right: 0.5em;
      font-weight: 400;
      color: var(--border-color);
      background-color: var(--byma-button-gray);
      border-radius: 20px;
      max-width: 97%;
      margin: 2px;
      width: 80%;
    }
    .bymaDatePickerNone {
      background-color: transparent !important;
      border: none !important;
      outline: none;
    }
  }

  .bymaDatePickerSmall {
    .fieldLabel {
      padding-left: 0.5em;
      font-size: $font_large;
      text-align: center;
      line-height: 15.4px;
      letter-spacing: 0.07px;
      font-family: var(--font-family-regular);
    }

    .wrapperDatePicker input {
      font-size: 12px !important;
      padding-left: 0.5em;
      padding-right: 0.5em;
      line-height: 15.4px;
      letter-spacing: 0.07px;
      font-weight: 400;
      color: var(--border-color);
      background-color: var(--byma-button-gray);
      border-radius: 10px;
      max-width: 97%;
      margin: 2px;
      width: 80%;
      height: 19px;
    }

    .wrapperDatePicker:focus-within {
      outline: none !important;
      border: 1px solid #1a73e8 !important;
      background-color: transparent !important;
    }

    .bymaDatePickerNone {
      background-color: transparent !important;
      border: none !important;
      outline: none;
    }
  }
  /* Imput end*/

  .calendarDatePicker {
    background-color: var(--secondary-gray-light);
    border-color: var(--secondary-gray-light);
    border-radius: 20px;
  }

  .clearButtonDatePicker {
    background-color: transparent;
    position: relative;
    height: 100%;
  }

  .clearButtonDatePicker::after {
    height: 100%;
    font-weight: 500;
    font-size: 15px;
    background-color: transparent;
    position: relative;
    right: 22px;
    bottom: 2px;
  }

  .timeDatePicker {
    color: white;
    background-color: transparent;
  }

  .timeDatePicker:hover {
    --bs-bg-opacity: 1;
    background-color: var(--secondary-gray-normal) !important;
  }

  .monthDatePicker {
    color: white;
    background-color: yellow;
  }

  .weekDayDatePicker {
    color: white;
    background-color: transparent;
  }

  .dayDatePicker {
    color: white;
    background-color: transparent;
    border-radius: 2px !important;
  }

  .dayDatePicker:hover {
    --bs-bg-opacity: 1;
    // background-color: var(--secondary-gray-normal) !important;
    background-color: #0183d9 !important;
  }
}

.bymaDatePickerCustomHeader {
  color: white;
  font-family: var(--font-family-regular);
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  border-bottom: 1px solid #d2d4da !important;

  span {
    align-self: center;
  }

  .bymaDatePickerLeftButtons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-left: 5px;
  }

  .bymaDatePickerRightButtons {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-right: 5px;
  }

  .bymaDatePickerButon,
  .bymaDatePickerButon:hover {
    background-color: transparent !important;
    border: none !important;
    padding-left: 0px;
    padding-right: 5px;
    font-size: 14px;
    font-weight: 300;
    font-family: monospace;
  }

  .bymaDatePickerButon:focus {
    box-shadow: none !important;
  }
}

.clockLayout {
  background-color: #302d38;
  border-radius: 20px;
}
.clockToolbar {
  span {
    color: #ffffff;
  }
  button {
    background-color: #494550 !important;
    span {
      padding: 5px !important;
      color: #ffffff !important;
    }
  }
}

.clockActionBar {
  button {
    color: #cebafd;
  }
}

.inputAdornment {
  button {
    svg {
      fill: #ffffff;
    }
  }
}

.readOnly {
  border-color: #6c757d !important;
}
