.oferta-extendida-modal {
  .modal-dialog {
    //PABLO agrando un poco para que no haya overflow en campo Numero Comitente
    width: 842px;
  }
  .modal-header {
    padding: 20px;

    .modal-title {
      font-family: var(--font-montserrat-light);
      line-height: 30px;
      font-size: 20px;
      letter-spacing: 1px;
      &::first-letter {
        font-size: 25px;
      }
    }
  }

  .modal-body {
    padding: 0;
    //PABLO mayor height para que entren los campos como en disenio figma
    max-height: 600px;
    //max-height: 540px;

    overflow: auto;

    #oferta-rapida-edit-form > div {
      padding: 0 10px !important;
    }

    .oferta-extendida-no-competitivo {
      padding: 0 30px;
      height: 27px;

      label {
        font-family: 'Inter';
        font-size: 12px;
        letter-spacing: 0.06px;
      }
    }

    .bymaCardContainer {
      padding: 15px;
    }
  }
  & ::-webkit-scrollbar {
    width: 8px;
  }

  & ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

    background-color: #4e5261;
    border-radius: 10px;
  }

  & ::-webkit-scrollbar-thumb {
    background-color: #262a3d;
    outline: none;
    border-radius: 10px;
  }
  scrollbar-color: #262a3d transparent;
  scrollbar-width: auto;
}
