.bymaPageFrame {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 12px;

  .bymaPageBreadcrumb {
    margin-top: 20px;
    height: 13px;
  }

  .bymaPageTitle {
    margin-top: 30px;
    margin-bottom: 23px;
    margin-left: 0;
    margin-right: 0;
    position: relative;
  }

  .bymaPageToast {
    margin-top: 10px;
    position: absolute;
    right: 0px;
    bottom: 25px;
    max-width: 346px;
    width: fit-content;
    padding: 0;
  }

  .toastContainer {
    margin-top: 1%; /*TODO por ahora se reduce el espacio para os toast, ocupaba demasiado*/
    margin-right: 1%;
    right: 0px;
    position: fixed;
    z-index: 999;
  }
}
