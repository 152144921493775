@import '../../../variables.scss';

.bymaNumberField {
  //PABLO TODO no utilizar margenes exteriores en lso componentes
  //margin-bottom: 16px;

  .fieldLabel {
    color: white;
    margin-bottom: 8px;
    font-weight: $font_weight_bold;
    font-size: 16px;
    line-height: 24px;
    font-family: var(--font-family-regular);

    //En la version default esta alineado a izq totalmente
    //padding-left: 0.5em;
  }

  .inputInnerAddon input[type='password'] {
    -webkit-text-security: square;
    width: 100%;
  }

  .inputNumberFormField {
    color: white;
    outline: none;
    border: none;
  }

  .inputNumberFormField:read-only {
    pointer-events: none;
  }

  //.inputInnerAddon:read-only,
  .inputInnerAddonReadOnly {
    border: 1px solid var(--gray-color-dark) !important;
  }

  .inputInnerAddonLarge {
    padding: 12px 12px 12px 13px;
  }

  .inputInnerAddon:focus-within {
    border: 1px solid var(--byma-primary-2);
  }

  .inputInnerAddon {
    position: relative;

    width: 100%;
    border: 1px solid var(--byma-gray-2);
    border-radius: 20px;
    display: flex;
    align-items: center;

    &.inputNumberFormFieldError {
      border: 1px solid var(--badge-text-red);
    }

    input {
      width: 100%;
      padding: 0;
      line-height: 100%;
    }

    //Hacky solution for the autofill styling
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000000s ease-in-out 0s;
      -webkit-text-fill-color: white;
    }

    .inputLeftAddon {
      pointer-events: none;
    }


    .leftIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    .errorText {
      margin-top: 4px;
    }
  }
  .inputInnerAddonSmall {
    padding: 6px 12px 6px 6px;
    font-size: 12px;
    .leftIcon {
      width: 16px;
      height: 16px;
      img {
        max-width: 14px;
      }
    }

    input {
      line-height: 14px;
      padding: 1px 6px 1px 10px;
    }
  }

  .inputInnerAddonDefault {
    padding: 6px 12px;

    input {
      font-family: var(--font-family-regular);
      font-size: $font_large;
      font-weight: $font_weight_light;
      line-height: 24px;
      letter-spacing: 0.07px;
    }
  }
}
.bymaNumberFieldSmall {
  .fieldLabel {
    font-size: $font_large;
    line-height: 16px;
    letter-spacing: 0.07px;
  }
}
