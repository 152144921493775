// .ofertaRapidaForm {
//background: var(--secondary-gray-light) !important;
// background-color: var(--secondary-gray-light) !important;
// }

.ofertaExtendidaDescription {
  display: flex;
  width: 100%;
  height: 23px;
  font-size: 14px;
  font-family: var(--font-family-regular);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 400;
  border-bottom: 1px solid var(--byma-gray-2);
  margin-bottom: 20px;
}

.ofertaExtendidaTitle::before {
  content: url('../../../../public/images/icons/plus.svg') !important;
  padding-top: 1%;
}

.ofertaExtendidaTitle {
  font-family: var(--font-montserrat-light);
  font-weight: 300;
  font-size: 20px;
  letter-spacing: 0.05em;
  margin-left: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ofertaRapidaTitle_container {
  margin-bottom: 10px;
}
.ofertaRapidaTitle {
  font-size: 14px;
  font-family: var(--font-family-regular);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 400;
  padding-bottom: 8px;
  p {
    margin: 0;
  }
}
.line {
  height: 1px;
  background-color: var(--byma-gray-2);
  opacity: 0.5;
}

.footerOfertaExtendida_container {
  display: flex;
  align-items: center;
}

.buttonOfertaExtendida {
  background-color: var(--byma-button-gray) !important;
  border: 1px solid var(--byma-button-gray) !important;
  cursor: pointer !important;
  pointer-events: all;
}

.buttonOfertaExtendida_container {
  margin: 5px;
}