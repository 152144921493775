.ColocacionBoardContent {
  //display: flex;
  //flex-direction: column;
  gap: 2%;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 4%;

  .libroOfertasColocacion {
    .cardButtonsList {
      display: flex;
      flex-direction: row;
      gap: 15px;
      padding-top: 5%;
    }
  }
}

.SimulacionCorteContent {
  display: flex;
  flex-direction: column;
  gap: 2%;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 20px;

  .SimulacionCorteForm {
    .divider {
      border-bottom: 1px solid var(--border-color);
    }
  }

  .simulacionCorteResume {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .resumeCorteCol {
      justify-content: left;
      display: flex;
    }
  }
}

.OfertasPorValorContent {
  display: flex;
  flex-direction: column;
  gap: 2%;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 20px;

  .OfertasPorValorForm {
    .divider {
      border-bottom: 1px solid var(--border-color);
    }
  }

  .OfertasPorValorResume {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.boardItemContainer {
  margin-top: 20px;
}
