@import '../../../variables.scss';

.filtersForm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: inherit;
  gap: 16px;
}

.filtersButtonCol {
  align-items: center;
  flex-direction: column-reverse;
  display: inline-flex;
}

.filtersButton {
  float: right;
  margin-bottom: 20px;
  cursor: pointer;
}

.filterBtnContainer {
  width: 100%;
  height: 100%;
}
