// Typographies
@mixin h1 {
  font-family: var(--font-montserrat-light);
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
}
@mixin h2 {
  font-family: var(--font-montserrat-light);
  font-size: 20px;
  font-weight: 300;
  line-height: 150%;
}
@mixin h4 {
  font-family: var(--font-family-light);
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
}
@mixin h5 {
  font-family: var(--font-family-light);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.login {
  background: linear-gradient(
      228deg,
      var(--transparent-black-4) 44%,
      var(--transparent-black-2) 82%
    ),
    linear-gradient(
      256deg,
      rgba(28, 27, 31, 1) 54%,
      var(--transparent-black-2) 79%,
      var(--transparent-black-3) 96%
    ),
    url('https://static.overlay-tech.com/assets/99b83792-516c-452c-85e2-e76548d67097.png'),
    rgba(0, 58, 112, 1);

  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  padding: 0 20px;
  display: flex;

  h1 {
    letter-spacing: 0.005em;
    text-transform: uppercase;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
    @include h1;
  }

  .loading {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .rowWrapper {
    padding-left: 20px;
    padding-right: 20px;
    flex: 1;
    display: flex;
  }

  .logo {
    position: absolute;
    top: 46px;
    left: 20px;
  }

  .form {
    display: flex;
    flex-direction: column;

    .loginHeader {
      .title {
        font-style: normal;
        line-height: 30px;
        color: var(--primary-color-normal);
        margin: 0;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        @include h2;
      }

      .subtitle {
        color: var(--byma-blanco);
        margin: 6px 0 0 0;
        @include h5;
      }
    }

    .formInput {
      font-family: var(--font-family-light);
    }

    .submitButton {
      width: 100%;
    }

    .submitButtonGroup {
      margin-top: 64px;
    }

    .recoveryQuestion {
      color: var(--byma-primary-2) !important;
      text-decoration: none;
      cursor: pointer;
      display: inline-block;
      margin: 32px auto 0;
      width: fit-content;
      @include h5;
    }

    .loginErrorAlert {
      width: 100%;
    }

    .loginRecoveryAlert {
      margin-top: 24px;
    }

    .loginRecoveryAlert p {
      margin: 0;
      line-height: 24px;
    }

    .loginErrorAlert p,
    .loginRecoveryAlert p {
      font-weight: 400;
      line-height: 24px;
      text-decoration: none;
      cursor: pointer;
      @include h5;

      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
