.singleAccordionContainer {
  .singleAccordionItem {
    background-color: transparent !important;
    border: none !important;
    color: #f8f9fa !important;

    .singleAccordionHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .accordionHeaderTitle {
        background-color: transparent !important;
        border: none !important;
        color: #f8f9fa !important;
        font-family: var(--font-family-regular);
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }

      .singleAccordionButton {
        border: none;
        background-color: transparent;
      }
    }

    .singleAccordionBody {
      margin-top: 25px;
    }
  }
}