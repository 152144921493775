.bymaHelpContainer {
  font-family: var(--font-family-regular);
  font-weight: 400;
  line-height: 24px;

  .editBtnContainer {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .editFiller {
    margin-top: 48px;
    margin-bottom: 48px;
    //height: 33px;
  }

  .bymaHelpItem {
    margin-bottom: 48px;

    .bymaCardContainer {
      min-height: 200px !important;
      background-color: red !important;
    }

    p {
      margin: 0;
    }
    .bymaHelpButton {
      font-family: var(--font-montserrat-light);
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-size: 16px;
      font-weight: 300;
      padding: 10px 16px;
    }

    a {
      color: white;
    }
  }

  .ayudaHelpBox {
    font-size: 16px;
    margin-bottom: 48px;
  }

  .bymaHelpEditItem {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
  }

  .contactHelpButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 10px;
  }

  .contactText {
    white-space: nowrap;
    padding-right: 10px;
  }
}

.footerBtnRoles_container {
  width: 100%;
}
.footerBtnRoles_btns_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 67px;
}
.footerBtnRoles {
  padding: 0 10px;
}
.footerBtnRoles_backBtn {
  margin-bottom: 33px;
}
