.container {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 0;
  margin-top: 36px;
}

.panel {
  flex: 1;
  padding: 0;
  padding: 0;
  border: none;
  margin: 0;
}

.exportButtomCol {
  //padding-top: 25px;
  padding-left: 20px;
}

.colocacionesHistoryButton {
  background-color: var(--byma-button-gray) !important;
  border: 1px solid var(--byma-button-gray) !important;
  cursor: pointer !important;
  pointer-events: all;
  align-self: baseline;
}

.noRowsDiv {
  text-align: center;
}