.navDropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--byma-button-gray);
  border-radius: 18px;
  padding: 6px 16px;
  a {
    color: #ffffff !important;
  }

  .dropdown-menu {
    background-color: var(--byma-button-gray) !important;
    margin-top: 10px;
    border-radius: 18px;
    padding: 12px 16px;
    gap: 8px;
    position: absolute;
    inset: 0px 0px auto auto;
    transform: translate(0px, 33px) !important;
  }
}

.linkContainer {
  color: #ffffff;
  padding: 6px 8px;
  &:hover {
    background-color: #4e5261;
    border-radius: 18px;
  }
}

