[data-theme='dark'] .bymaCardClassContainerDefault {
  //background: var(--secondary-gray-normal) !important;
  //border-radius: 20px;
  //padding: 20px;
  position: relative;

  .bymaCardTitle {
    padding: 0px 4px;
    gap: 10px;
    position: absolute;
    left: 24px;
    top: -8px;
    border-radius: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
  }

  .bymaCard {
    height: 100%;
    width: 100%;
    position: static;
    border-radius: 20px;
    border: none;

    .bymaCardHeader {
      background: transparent;
      border: none;
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: space-between;
      padding: 0;

      .headerSpan {
        font-family: var(--font-family-regular);
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .form-input {
      width: 90%;
    }

    .bymaCardBody {
      padding: 0;
    }

    .bymaCardFooter {
      background: transparent;
      border: none;
      display: flex;
      justify-content: flex-end;
      padding: 0;
    }
  }
}

[data-theme='dark'] .bymaCardClassContainerColocacion {
  //background: var(--secondary-gray-normal) !important;
  //border-radius: 20px;
  //padding: 20px;
  position: relative;

  .bymaCardTitle {
    padding: 0px;
    position: relative;
    left: 0;
    top: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 20px;
  }

  .bymaCard {
    height: 100%;
    width: 100%;
    position: static;
    border-radius: 20px;
    border: none;

    .bymaCardHeader {
      background: transparent;
      border: none;
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: space-between;
      padding: 0;

      .headerSpan {
        font-family: var(--font-family-regular);
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .form-input {
      width: 90%;
    }

    .bymaCardBody {
      padding: 0;
    }

    .bymaCardFooter {
      background: transparent;
      border: none;
      display: flex;
      justify-content: flex-end;
      padding: 0;
    }
  }
}

[data-theme='dark'] .bymaCardClassContainerColocacionNew {
  //background: var(--secondary-gray-normal) !important;
  //border-radius: 20px;
  //padding: 20px;
  position: relative;
  border:  1px solid var(--byma-primary-2);

  .bymaCardTitle {
    padding: 0px 4px;
    gap: 10px;
    position: absolute;
    left: 24px;
    top: -8px;
    border-radius: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
  }

  .bymaCard {
    height: 100%;
    width: 100%;
    position: static;
    border-radius: 20px;
    border: none;

    .bymaCardHeader {
      background: transparent;
      border: none;
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: space-between;
      padding: 0;

      .headerSpan {
        font-family: var(--font-family-regular);
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .form-input {
      width: 90%;
    }

    .bymaCardBody {
      padding: 0;
    }

    .bymaCardFooter {
      background: transparent;
      border: none;
      display: flex;
      justify-content: flex-end;
      padding: 0;
    }
  }
}

