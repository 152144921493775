.byma-pagination {
  background-color: transparent;
  padding-top: 2%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  .page-item > .page-link {
    background-color: transparent;
    color: var(--byma-gray-2);
    border: none;
  }

  .page-item > .page-link:hover {
    border-radius: 20px !important;
    background: var(--secondary-gray-light) !important;
    border: none;
    color: white;
  }

  .page-item.disabled {
    .page-link {
      background-color: transparent;
    }
  }

  .page-item.disabled {
    .page-link {
      background-color: transparent !important;
    }
  }

  .page-item.active {
    .page-link {
      border-radius: 20px !important;
      background: var(--byma-button-gray) !important;

      span {
        background-color: transparent;
      }
    }
  }
}
