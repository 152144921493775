[data-theme='dark'] .app-navbar {
  max-height: 60px !important;
  height: 50px !important;
  background: var(--primary-gray-dark);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  --bs-bg-opacity: 0;

  .navbar-brand {
    margin-right: 4%;
  }

  .app-navbar-content {
    width: calc(100% - 40px);
  }

  .navbar-logo img {
    width: 180px;
    height: 27.12px;
  }

  .app-navbar-items {
    align-items: center;

    /* Por ahora queda asi hasta que se defina bien lo del tema */
    .nav-link {
      color: rgba(255, 255, 255, 0.4) !important;
      min-width: 140px;
      text-align: center;
      padding-left: 7%;
      padding-right: 7%;
      white-space: nowrap;
    }

    .nav-item {
      color: rgba(255, 255, 255, 0.4) !important;
      text-align: center;
      white-space: nowrap;
      cursor: pointer;
      padding-left: 7%;
      padding-right: 7%;
    }

    .nav-link::after {
      display: none;
    }

    .active {
      //background-color: transparent;
      color: #ffffff !important;
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: 18px;
      color: var(--selected-color) !important;
      //height: 35px;
    }

    // Esto es para cuando tengamos lso botones de la barra
    /*.active {
      color: #ffffff !important;
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: 18px;
      height: 35px;
    }*/
  }

  .config-dropdown {
    .dropdown-menu {
      margin-top: 5%;
      background: var(--byma-button-gray);
      border-color: var(--byma-button-gray);
      //padding: 12px 16px 12px 16px;

      a {
        color: white !important;
        padding-top: 0px;
        padding-bottom: 3px;
        padding-left: 5%;
        padding-right: 5%;
      }

      a:hover {
        background: var(--secondary-gray-light);
      }

      .dropdown-item {
        .navbar-dropdown-icon {
          padding-right: 10%;
        }

        span {
          padding-left: 0%;
        }
      }

      .user-label {
        margin-left: 4px;
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .right-buttons {
    .config-dropdown>.nav-link::after {
      display: none;
    }

    .dropdown-menu {
      margin-top: 35% !important;
      left: -145px;
      width: 188px;
      //height: 165px;
    }

    .navbar-button,
    .config-dropdown>.nav-link {
      color: rgba(0, 0, 0, 0.65);
      text-align: center;

      // Figma
      background: var(--gray-color-dark);

      border-radius: 50px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px;
      gap: 10px;
      width: 27px;
      height: 27px;
    }

    .navbar-button,
    .nav-link:first-of-type {
      margin-left: 16px;
    }

    .active {
      background-color: transparent;
    }

    .dropdown-item:hover {
      background-color: transparent;
    }

    .navbar-button-icon {
      width: 95%;
    }
  }
}