.historialColocacionesContainer {
  // margin-right: auto;
  // margin-left: auto;
  // width: calc(100% - 40px);
}

.infoColumnBadge {
  background: var(--badge-background-blue);
  color: var(--badge-text-blue);
  border-radius: 20px;
  height: 60%;
  text-align: left;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  top: 20%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bymaMultiSelect {
  min-width: 140px !important;
}

.exportBtnRow {
  padding-bottom: 20px;
}

.filterBtnClassName {
  margin: 0;
  width: 48px;
  height: 33px;
}

.listFiltersRowContainer {
  padding-bottom: 20px;
}

.filterButtonContainer {
  padding-top: 22.39px;
  position: relative;
}

.filterBtnCol {
  width: 100px;
}
