.info-bar-container {
  font-family: var(--font-family-regular);
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  background-color: var(--secondary-gray-normal);
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
  height: 26px;
  padding: 10px;
  float: right;
  align-items: center;
  justify-content: flex-end;
}