.bymaUploadField {
  margin-bottom: 16px;

  .fieldLabel {
    color: white;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .inputInnerAddon input[type='password'] {
    -webkit-text-security: square;
    width: 100%;
  }

  .inputFormField {
    color: white;
    outline: none;
    border: none;
    background: transparent;
    background-color: transparent !important;
    border-color: transparent;
  }

  /*.inputFormField:read-only {
      pointer-events: none;
    }*/

  //.inputInnerAddon:read-only,
  .inputInnerAddonReadOnly {
    border: 1px solid var(--gray-color-dark) !important;
  }

  .inputInnerAddonDefault {
    padding: 12px 12px 12px 13px;
  }

  .inputInnerAddonSmall {
    padding: 6px 12px;

    .leftIcon {
      width: 16px;
      height: 16px;

      img {
        max-width: 14px;
      }
    }
  }

  .inputInnerAddon:focus-within {
    border: 1px solid var(--byma-primary-2);
  }

  .inputInnerAddon {
    position: relative;

    width: 100%;
    border: 1px solid var(--byma-gray-2);
    border-radius: 20px;
    display: flex;
    align-items: center;

    &.inputFormFieldError {
      border: 1px solid var(--badge-text-red);
    }

    input {
      width: 100%;
      padding: 0;
      line-height: 21px;
    }

    ::-webkit-file-upload-button {
      display: none;
    }

    //Hacky solution for the autofill styling
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000000s ease-in-out 0s;
      -webkit-text-fill-color: white;
    }

    .inputLeftAddon {
      pointer-events: none;
    }

    .inputRightAddon {
      width: 20.9px;
    }

    .leftIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    .errorText {
      margin-top: 4px;
    }
  }
}

.BymaUploadButton {

  .buttonInnerAddonReadOnly {
    border: 1px solid var(--gray-color-dark) !important;
  }

  .buttonInnerAddonDefault {
    padding: 12px 12px 12px 13px;
  }

  .buttonInnerAddonSmall {
    padding: 4px 12px;
    font-size: 14px;

    .leftIcon {
      width: 16px;
      height: 16px;

      img {
        max-width: 14px;
      }
    }
  }

  .buttonInnerAddon:focus-within {
    border: 1px solid var(--byma-primary-2);
  }

  .buttonInnerAddon {
    position: relative;
    font-family: var(--font-family-regular);
    background-color: var(--byma-button-gray);
    border: 1px solid var(--byma-button-gray);
    width: 100%;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    label {
      cursor: pointer;
    }

    &.inputFormFieldError {
      border: 1px solid var(--badge-text-red);
    }

    ::-webkit-file-upload-button {
      display: none;
    }

    .inputRightAddon {
      width: 20.9px;
    }

    .leftIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    .errorText {
      margin-top: 4px;
    }
  }
}