.bymaTextField {
  margin-bottom: 16px;

  .fieldLabel {
    color: white;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .inputFormField {
    color: white;
    outline: none;
    border: none;
    scrollbar-color: var(--primary-gray-dark) transparent;
    scrollbar-width: thin;
  }

  .inputFormField:read-only {
    pointer-events: none;
  }

  //.inputInnerAddon:read-only,
  .inputInnerAddonReadOnly {
    border: 1px solid var(--gray-color-dark) !important;
  }

  .inputInnerAddon:focus-within {
    border: 1px solid var(--byma-primary-2);
  }

  .inputInnerAddon {
    position: relative;
    padding: 12px;
    width: 100%;
    border: 1px solid var(--byma-gray-2);
    border-radius: 20px;
    display: flex;
    align-items: center;

    &.inputFormFieldError {
      border: 1px solid var(--badge-text-red);
    }

    input {
      width: 100%;
      padding: 0;
      line-height: 21px;
    }

    //Hacky solution for the autofill styling
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000000s ease-in-out 0s;
      -webkit-text-fill-color: white;
    }

    .errorText {
      margin-top: 4px;
    }
  }

  .inputFormField::-webkit-scrollbar {
    width: 7px !important;
    height: 0px !important;
  }
  .inputFormField::-webkit-scrollbar-track {
    background: var(--secondary-gray-normal) !important;
    border-radius: 50px !important;
  }
  .inputFormField::-webkit-scrollbar-thumb {
    background: var(--primary-gray-dark) !important;
    border-radius: 50px !important;
  }
  .inputFormField::-webkit-scrollbar-thumb:hover {
    background: var(--primary-gray-dark) !important;
  }
}
