.byma-toast {
  margin-bottom: 1%;
}

.error-toast {
  //background-color: rgba(247, 85, 85, 0.1) !important;
  background: linear-gradient(0deg, rgba(59, 63, 80, 0.9), rgba(59, 63, 80, 0.9)),
    linear-gradient(0deg, rgba(247, 85, 85, 0.5), rgba(247, 85, 85, 0.5)),
    linear-gradient(0deg, rgba(247, 85, 85, 0.3), rgba(247, 85, 85, 0.3));
}

.success-toast {
  //background-color: rgba(79, 174, 34, 0.1) !important;
  background: linear-gradient(0deg, rgba(59, 63, 80, 0.9), rgba(59, 63, 80, 0.9)),
    linear-gradient(0deg, rgba(79, 174, 34, 0.5), rgba(79, 174, 34, 0.5)),
    linear-gradient(0deg, rgba(79, 174, 34, 0.3), rgba(79, 174, 34, 0.3));
    //border: 1px solid #4FAE224D
}
