.bymaDateInput {
  //min-width: 150px !important;

  .bymaDatePickerSmall {
    input[type='checkbox'],
    input[type='radio'] {
      background-color: transparent !important;
      border-color: var(--gray-color-dark);
    }
  }

  .bymaDatePickerSmall label {
    font-size: 14px;
    line-height: 15.4px;
    letter-spacing: 0.07px;
    font-family: var(--font-family-regular);
  }
}
