.toastContainer {
  margin-top: 1%; /*TODO por ahora se reduce el espacio para os toast, ocupaba demasiado*/
  margin-right: 1%;
  right: 0px;
  position: fixed;
  z-index: 999;
}

.dashboardContainer {
  margin-top: 36px;
}

.editarWidgetsContainer {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-top: 18px;
  
  .editarWidgetsBtn {
    background-color: transparent !important;
    border: 1px solid #ced4da !important;
  }
}
