.checkboxFormGroup {
  width: 100%;
  height: inherit;

  .bymaCheckbox {
  }

  .bymaCheckboxDefault {
    input[type='checkbox'],
    input[type='radio'] {
      background-color: var(--gray-color-dark);
      border-color: var(--gray-color-dark);
    }

    input[type='checkbox']:checked,
    input[type='radio']:checked {
      background-color: var(--byma-button-blue) !important;
      border-color: var(--byma-button-blue) !important;
    }

    input[type='checkbox'],
    input[type='checkbox']:checked {
      margin-right: 5%;
    }

    label {
      font-size: 16px;
      font-weight: 400;
      font-family: var(--font-family-regular);
    }
  }

  .bymaCheckboxSmall {
    input[type='checkbox'],
    input[type='radio'] {
      background-color: transparent !important;
      border-color: var(--gray-color-dark);
    }
  }

  .bymaCheckboxSmall label {
    font-size: 14px;
    line-height: 15.4px;
    letter-spacing: 0.07px;
    font-family: var(--font-family-regular);
  }
}
