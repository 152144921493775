.tituloDetail {
  color: white;
  padding: 0 20px 20px 20px;
  // background-color: red;
  .tituloDetail_rowEmision {
    margin-top: 10px;
    margin-bottom: 12px;
  }
  .tituloDetail_rowTramo {
    margin-bottom: 6px;
  }
  .tramoDivisor {
    height: 1px;
    border-top: 1px solid #ffffff;
    opacity: 0.2;
    margin: 9px 0px;
  }
}
