.bymaAccordion {
  background: transparent;
  padding-top: 0px !important;
  padding-bottom: 0px !important;

  .accordionItem {
    width: 100%;
    border: none;
    border-radius: 20px;
    margin-bottom: 5px;

    .leftButtonHeader {
      background: var(--secondary-gray-light);
      button {
        background-color: inherit;
      }

      button:not(.collapsed) {
        box-shadow: none;
        color: #ffffff !important;
      }

      button::after {
        display: none;
      }

      .leftButton {
        width: 24px;
        height: 24px;
        border: none;
        cursor: pointer !important;
        pointer-events: all;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s transform ease-in-out;
      }

      .leftButton::before {
        content: url('../../../public/images/icons/arrow-open.svg') !important;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer !important;
        pointer-events: all;
        display: inline-block;
      }

      .leftButton.collapsed::before {
        transform: rotate(0deg);
        transition: 0.3s transform ease-in-out;
      }

      .leftButton.not_collapsed::before {
        transform: rotate(-180deg);
        transition: 0.3s transform ease-in-out;
      }
    }

    .rightButtonHeader {
      width: 100%;
      button {
        border-radius: 20px;
        //background: var(--secondary-gray-light);
        background: transparent;
        //pointer-events: none;
      }

      button::after {
        background-image: url('../../../public/images/icons/arrow-open.svg');
        background-repeat: no-repeat;
        background-position: center;
        width: 24px;
        height: 24px;
        pointer-events: all;
      }

      button:not(.collapsed) {
        box-shadow: none;
        color: #ffffff !important;
      }
    }

    .accordionLabel {
      position: absolute;
      background: var(--gray-color-dark) !important;
      margin-left: 30px;
      padding-left: 5px;
      padding-right: 5px;
      border-radius: 20px;
      font-family: var(--font-roboto-regular);
      top: -8px;
      height: 16px;
      letter-spacing: 0.5px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;

      span {
        vertical-align: super;
      }
    }

    .accordionBody {
      width: 100%;
      border-radius: 20px;
    }
  }
  .activeItem {
    background-color: #555967 !important;
    border-radius: 20px;
  }
  .accordionItem,
  .accordionItem:first-of-type,
  .accordionItem:last-of-type {
    border-radius: 20px !important;    
    background-color: transparent;
    gap: 20px;

    button.colapsed {
      border-radius: 20px !important;
    }
  }
}
