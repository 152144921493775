.byma-tabs {
  width: 100%;
  height: 100%;

  .byma-tabs-close-button {
    float: right;
    background-color: transparent !important;
    border: none !important;
    background-image: url('./../../../public/images/icons/close.svg');
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 5%; 
    background-position: center;
  }

  .byma-tab-header {
    .nav-item {
      width: 49%;
      .nav-link.active {
        background-color: var(--secondary-gray-light) !important;
        border: none;
      }

      .nav-link {
        width: 100%;
        background-color: rgba(78, 82, 97, 0.5) !important;
        border-radius: 20px 20px 0px 0px;
        //width: 290px;
        height: 40px;
        color: white !important;
      }
    }
  }

  .tab-content {
    //background-color: var(--secondary-gray-light) !important;
    //border-radius: 0px 20px 20px 20px;

    .byma-tab-content,
    .byma-tab-content.active,
    .byma-tab-content.show {
      background-color: var(--secondary-gray-light) !important;
      border-radius: 0px 20px 20px 20px;
    }
  }
}
