.Connector-0-2-21,
.Connector-0-2-15 {
  //border: 1px solid;
}

.Label-0-2-11 {
  //color: #979191;
}

.byma-stepper-buttons {
  float: right;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-top: 5%;
}

.byma-custom-step {
  font-size: 24px !important;
  padding: 10px !important;
  width: 44px !important;
  height: 44px !important;
  //pointer-events: none;
}

.byma-stepper-local-alert {
  padding: 0px;
}

#RFS-ConnectorContainer {
  left: calc(-37% - 6px);
  right: calc(63% - 6px);
}
