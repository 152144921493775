.buttonGroup {
  --bs-btn-font-family: var(--font-family-regular);

  .bymaButton {
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 6px 16px;
    box-shadow: none !important;
  }
  .bymaButtonLarge {
    padding: 8px 16px;
    font-size: 16px;
    line-height: 32px;
  }
  .buttonLeftAddon {
    cursor: pointer;
  }
}
